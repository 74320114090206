const questions = [
  {
      question: '種類',
      name: 'kind',
      answers: ['ノート', 'デスクトップ', '未定'],
      labelIds: ['notebook', 'desktop', 'allpc'],
      checked: 3
  },
  {
      question: 'コストパフォーマンス(価格の割にハイスペック)',
      name: 'cospa',
      answers: [1,2,3,4,5],
      checked: 3
  },
  {
      question: 'サポート品質(メール・電話・マニュアルなど)',
      name: 'support',
      answers: [1,2,3,4,5],
      checked: 3
  },
  {
      question: 'デザインの洗練度',
      name: 'design',
      answers: [1,2,3,4,5],
      checked: 3
  },
  {
      question: 'カスタマイズ性(購入後にパーツを追加し機能拡張)',
      name: 'customize',
      answers: [1,2,3,4,5],
      checked: 3,
      targetDevice: 'desktoponly'
  },
  {
      question: '軽量・長時間バッテリー',
      name: 'battery',
      answers: [1,2,3,4,5],
      checked: 3,
      targetDevice: 'noteonly'
  },
  {
      question: 'Space(スペース):省スペースにこだわるか',
      name: 'space',
      answers: [1,2,3,4,5],
      checked: 3,
      targetDevice: 'desktoponly'
  },
  {
    question: '家ではモニターにつなげて大きな画面で作業がしたい',
    name: 'externalMonitor',
    answers: ['いいえ','はい','分からない'],
    checked: 1,
    targetDevice: 'noteonly'
　},
  {
    question: 'ヘッドホン、マウスなど接続機器は多くなる予定だ',
    name: 'multiconnect',
    answers: ['いいえ','はい','分からない'],
    checked: 1,
    targetDevice: 'noteonly'
  },
  {
      question: '外出先で用いたいか',
      name: 'goOut',
      answers: ['主に家', '稀に', '用いたい', '分からない'],
      checked: 1,
      targetDevice: 'noteonly'
  },
  {
      question: '配線はすっきりさせたい',
      name: 'wire',
      answers: ['気にならない', 'はい', 'できれば'],
      checked: 1,
      targetDevice: 'desktoponly'
  },
  {
      question: '本格的な3DゲームやVRを楽しむことが目的である',
      name: 'game',
      answers: ['いいえ', 'はい', '少し興味はある'],
      checked: 1
  },
  {
      question: '動画を切り貼りしてテロップを付けるなど簡単な動画編集をしてみたい',
      name: 'editMovie',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '4K動画編集や、特殊なエフェクトなどを使った動画編集を趣味として楽しみたい',
      name: 'editProMovie',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
    question: '3DモデリングやAI画像生成などを使った本格的なクリエイティブな作業が行いたい',
    name: 'editGraphics',
    answers: ['いいえ', 'はい', '分からない'],
    checked: 1
  },
  {
    question: '何かの作業よりも動画視聴が主な用途である',
    name: 'movieMain',
    answers: ['いいえ', 'はい', '分からない'],
    checked: 1
  },
  {
      question: 'パソコンの主な目的はSNSやWebサイトの利用である',
      name: 'internetMain',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '主にビジネス用途で使用する予定である',
      name: 'businessMain',
      answers: ['いいえ', 'はい'],
      checked: 1
  },
  {
      question: 'パソコンを使う頻度は',
      name: 'often',
      answers: ['稀に','週に2,3回','ほぼ毎日','分からない'],
      checked: 1
  },
  {
      question: 'パソコンでやりたいことをあまり決めていない',
      name: 'firstBuy',
      answers: ['いいえ','はい'],
      checked: 1
  },
  {
      question: 'テレビをパソコンの視聴や録画が行いたい',
      name: 'showTV',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '大量に撮りためた動画やファイルがあり、新しいパソコンに移したい',
      name: 'muchStorage',
      answers: ['いいえ', 'はい'],
      checked: 1
  },
  {
      question: '今、あるいはこれから大学生である',
      name: 'student',
      answers: ['いいえ', 'はい'],
      checked: 1
  },
  {
      question: 'WordかExcelは必要か？(文書作成、家計管理、学習計画など)',
      name: 'officePersonal',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: 'PowerPointは必要か？(プレゼン、旅行計画、家族のアルバム作成など様々な用途)',
      name: 'officeBusiness',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  },
  {
      question: '音楽CDを取り込みや、BDやDVDの再生/書き込みが行いたい',
      name: 'playDVD',
      answers: ['いいえ', 'はい', '分からない'],
      checked: 1
  }
];

export default questions;