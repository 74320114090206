import MakerArray from "./../datas/maker";

const analyze = params => {
    let makerList = [],
    specList = [],
    checkList = [];

    let device;
    switch (params.kind) {
        case 1:
            device = "notebook";
            break;
        case 2:
            device = "desktop";
            break;
        default:
            device = "allpc"
    }

    // ノートPCかデスクトップPCか？
    let isDeskTop = true;
    if (device === "desktop") {
        specList.push('種類:デスクトップパソコン');
    } else if (device === "notebook") {
        specList.push('種類:ノートパソコン');
        isDeskTop = false;
    } else if (device === "allpc") {
        if (params.goOut === 3 /*often*/) {
            specList.push('種類:ノートパソコン');
            isDeskTop = false;
        } else {
            let deskTopScore = 0;
            let noteScore = 0;
            if (params.cospa === 5) {
                deskTopScore++;
                checkList.push('ノートパソコンは省電力設計のCPUを用いているため、デスクトップパソコンの方がコストパフォーマンスは高い');
            }
            if (params.game === 2) {
                deskTopScore++;
            }
            if (params.editGraphics === 2) {
                deskTopScore++;
            }
            if (params.goOut === 2) {
                noteScore += 1;
            }
            if (deskTopScore > noteScore) {
                specList.push('種類:デスクトップパソコン');
            } else {
                specList.push('種類:ノートパソコン');
                isDeskTop = false;
            }
            if (deskTopScore >= 2 && noteScore >= 1) {
                let tempStr = "使い方からは"
                if (isDeskTop) {
                    tempStr += "デスクトップ";
                } else {
                    tempStr += "ノート";
                }
                checkList.push(tempStr + 'をおすすめするが、持ち運びができるノートの利点と快適なデスクトップの利点を合わせ、最終的にどちらにすべきか判断しよう');
            }
        }
    }

    // ディスプレイ 解像度1366x768かそれ以上(FullHD(1920x1080)など)
    if (isDeskTop) {
        let _text = 'ディスプレイ: FullHD 23.8インチ程度が一般的';
        if (params.space <= 3 && params.movieMain !== 2 && (params.editGraphics === 2 || params.editProMovie === 2 || params.businessMain === 2)) { // 省スペースにこだわりは無く、動画視聴メインではない。
            _text += '。ウルトラワイドモニターも作業領域を広くとれおすすめ。';
            checkList.push('<a href="https://amzn.to/4hBSS9d" target="_blank">ウルトラワイドモニター</a>は34インチで解像度が3440x1440のものが作業効率的に良い。')
        }
        specList.push(_text);
    }

    //ノートパソコンの選び方
    let mobile = false;
    let mobility = false; // 14インチ以下のパソコンならtrue
    let standard = true;
    let gamingnote = false;
    if (!isDeskTop) {
        // サイズ
        if (params.goOut === 1 && params.externalMonitor !== 2) { //家で使うが外部モニター使用せず
            specList.push('サイズ:15.4インチ以上');
            if (params.game === 2 || params.editGraphics === 2) {
                gamingnote = true;
            } else {
                standard = true;
                checkList.push('15.6インチ程度がコスパが高く作業効率も良い');
            }
        }
        if (params.goOut >= 2) {
            if (params.game === 2 || params.editGraphics === 2 || params.editProMovie === 2) {
                specList.push('サイズ:15.6インチ');
                checkList.push('ゲームや動画編集を本格的に行うならば、携帯性はやや犠牲にせざるを得ない');
            } else {
                mobility = true;
                if (params.goOut === 2) {
                    standard = true;
                    specList.push('サイズ: 14インチ');
                    checkList.push('作業効率と持ち歩き時の手軽さを兼ね揃えた14インチはおすすめできる。');
                } else if (params.goOut === 3) {
                    mobile = true;
                    specList.push('サイズ:13.3または14インチ');
                    checkList.push('持ち運ぶ頻度が高ければ、できれば1.2kg以内のものがおすすめ');
                    checkList.push('バッテリー駆動時間には注意しよう。おおよそ記載されている時間の6~7割が実際に動作する時間である');
                } else {
                    standard = true;
                    specList.push('サイズ:14インチ');
                    checkList.push('外出先でどれぐらい使うか分からない場合に選ぶべき失敗の少ないサイズが14インチ');
                }
            }
        }
    }

    //デスクトップパソコンの選び方
    let allinone = false;
    let tower = false;
    if (isDeskTop) {
        if (params.space === 5 && params.wire >= 2 && params.customize <= 3 && params.game === 1) {
            specList.push('筐体:ディスプレイと本体の一体型');
            allinone = true;
        } else if (params.space === 4 && params.wire === 2 && params.customize <= 3  && params.game === 1) {
            specList.push('筐体:ディスプレイと本体の一体型');
            allinone = true;
        } else if (params.space <= 4 && params.customize === 5) {
            tower = true;
            specList.push('筐体:タワー型(大型)パソコン');
            if (params.space === 4) {
                checkList.push('余裕のある筐体を確保して必要に応じて機能を拡張しよう');
            }
        } else if (params.space <= 3 && (params.customize >= 4 || params.game >= 2 || params.editGraphics === 2)) {
            tower = true;
            specList.push('筐体:タワー型(大型)パソコン');
        } else if (params.game === 3) {
            tower = true;
            specList.push('筐体:タワー型(大型)パソコン');
            if (params.space >= 4) {
                checkList.push('省スペースと拡張性の両立を目指すならばミニタワーと呼ばれるPCがおすすめ');
            }
        } else {
            if (params.game >= 2 && params.editGraphics === 2) {
                tower = true;
                specList.push('筐体:タワー型(ミニタワー)パソコン');
                checkList.push('本格的な3Dゲームやグラフィックス系作業をする可能性が高い場合は、拡張性を考えてやや大きめのミニタワー型パソコンの購入を考えよう。');
            } else {
                specList.push('筐体:スリム型パソコン(幅10cm程度、ディスプレイと本体は分離)');
            }
        }
    }

    // CPU
    let cpuPoint = 0;
    let cpuSpec = "";
    params.editMovie >= 2 && cpuPoint++;
    (params.officePersonal >= 2 || params.officeBusiness >= 2) && cpuPoint++;
    params.often === 3 && cpuPoint++;
    params.firstBuy === 2 && cpuPoint++;
    params.often === 1 && cpuPoint--;
    params.internetMain === 2 && cpuPoint--;
    
    if (params.internetMain === 2) {
        cpuPoint--;
    }
    if (params.notGoodAt === 1 || params.support >= 4) {
        cpuPoint++;
    }
    if (params.game === 2 || params.editProMovie === 2 || params.editGraphics === 2) {
        cpuSpec = isDeskTop ? "30000" : "18000";
    } else if (params.game === 3 || params.editMovie >= 2 || params.editProMovie === 3 || params.editGraphics === 3) {
        cpuSpec = isDeskTop ? "20000" : "15000";
    } else {
        if (cpuPoint >= 1) {
            cpuSpec = "12500";
        } else {
            cpuSpec = "8000";
            if (params.often === 1) {
                checkList.push('使用頻度がそれほど高くないのであれば、コストパフォーマンスの点から低速なCPUで構わない');  
            }
        }
    }
    specList.push(`CPU: PASSMARKスコア${cpuSpec}点以上`);
    checkList.push(`PASSMARKスコアは<a href=/cpu/ target="_blank">CPU性能比較表</a>(別タブで開く)で詳細に確認可能`);

    // メモリ
    if (params.game >= 2 || params.editProMovie >= 2 || params.editGraphics >= 2) {
        specList.push('メモリ: 16GB');
        checkList.push('ゲームや本格的なクリエイティブな作業を行う場合はメモリ16GBは必要となってくる');
    } else if (params.businessMain === 2 && params.often === 3) {
        specList.push('メモリ: 16GB');
        checkList.push('毎日ビジネス用途で使うならば生産性や安定性の面で16GBは元が取れるだろう');
    } else if (params.firstBuy === 1) {
        specList.push('メモリ: 16GB');
        checkList.push('必要になった時のための保険として16GBあった方が良いだろう');
    } else {
        specList.push('メモリ: 8GB');
    }

    // グラフィックボード
    if (params.game === 2) {
        specList.push('グラフィックボード: Geforce RTX 4070 あるいは 4060など');
        if (isDeskTop) {
            checkList.push('4K画質、レイトレーシング、高フレームレートを目指す場合はGeforce RTX 4070などハイエンドGPUの搭載を推奨。');
        }
    } else if (params.editGraphics === 2) {
        specList.push('グラフィックボード: 何かしらを予算に応じて搭載');
        checkList.push('特に3D系のCGを扱う場合にはハイエンドなもので無くても問題ないが、何かしらグラフィックボードを搭載しておいた方が良い。');
    } else if (params.editGraphics >= 3 || params.game >= 3) {
        if (isDeskTop) {
            checkList.push('タワー(ミニタワー型)のPCならばゲームがしたくなった時にグラフィックボードを増設できる。');
        }
    }

    // ストレージ　(SSDかHDDかその両方か)
    if (params.game >= 2  || params.editProMovie >= 2 || params.editGraphics >= 2) {
        if (params.game === 2 && params.editProMovie === 2) {
            specList.push('SSD: 1TB');
        } else {
            specList.push('SSD: 512GB');
        }
    } else {
        if (!params.firstBuy && (params.often === 1 && params.internetMain)) {
            specList.push('SSD: 128GB');
            checkList.push('ネット中心や使用頻度が低く、保存するファイルがほとんど無いならば128GBでも問題ない');
        } else {
            specList.push('SSD: 256GB');
        }
    }

    // 追加ストレージ
    if (params.showTV === 2　|| params.muchStorage >= 2) {
        if (isDeskTop) {
            specList.push('HDD: 必要な容量追加。外付けも可');
        } else {
            checkList.push('必要に応じて<a href="https://amzn.to/48vSCUZ" target="_blank">USBメモリ</a>や<a href="https://amzn.to/3UxNCcE" target="_blank">外付けのストレージ</a>を購入し、動画や写真を保存しよう')
        }
    }

    // 光学ドライブ
    if (params.playDVD === 2) {
        specList.push('光学ドライブ: BDドライブまたはDVD(スーパー)マルチドライブ' + (mobility ? '(外付けで対応)' : ''));
        if (isDeskTop) {
            checkList.push('DVD(スーパー)マルチドライブは標準でついてくることが多いが念のため仕様の確認はしておこう');
        }
    } else {
        specList.push('光学ドライブ: 無し');
        checkList.push('CD/DVD/BDが必要になった場合は外付けドライブで対応すれば良い');
    }

    // Office
    if (params.officeBusiness === 2) {
        specList.push('オフィスソフト: Microsoft(R) Office Home and Business (Premium)');
    } else if (params.officePersonal === 2) {
        specList.push('オフィスソフト: Microsoft(R) Office Personal (Premium)');
    } else if (params.student === 2 && params.officeBusiness === 3) {
        specList.push('オフィスソフト: Microsoft(R) Office Home and Business (Premium)');
        checkList.push('大学生は研究発表やゼミでパワーポイントを使用するため、Home and Businessを選択するべきである。また社会人でもオフィスソフトを使うため、慣れ親しむためにも購入しておくとよい');
    } else if (params.officePersonal === 3 && params.firstBuy === 2) {
        specList.push('オフィスソフト: Microsoft(R) Office Personal (Premium)');
        checkList.push('迷っている場合はオフィスソフトを一つはもっておくと良い。日常的に使えるシーンは多いが、後から購入すると高いため');
    } else {
        specList.push('オフィスソフト: 無し');
    }
    // 大学生注意事項
    if (params.student === 2 && !isDeskTop && standard) {
        checkList.push('将来的に就職活動やゼミの発表でパソコンを持ち運ぶことを考えると14インチ程度のパソコンを買うことを検討しても良い');
    }
    // 端子
    if (!isDeskTop) {
        const _text = ""
        if (params.externalMonitor === 2 && params.multiconnect === 2) {
            specList.push('端子: Thunderbolt端子、USB TypeA端子(２つ以上)がついていること');
            checkList.push('Thunderbolt対応端子があることでデスクトップライクな環境の構築が可能で家の作業が快適になる')
            checkList.push('USB TypeAは機器接続に便利なため、あることを確認しよう');
        } else if (params.externalMonitor >= 2 && params.multiconnect >= 2) {
            checkList.push('Thunderbolt対応端子があることでデスクトップライクな環境の構築が可能で家の作業が快適になるため予算に応じて検討すると良い');
        } else if (params.multiconnect === 2) {
            specList.push('USB TypeA端子(２つ以上)');
            checkList.push('USB TypeAは機器接続に便利なため、あることを確認しよう');
        }
    }

    // テレビ
    if (params.showTV === 2) {
        if (isDeskTop && allinone) {
           specList.push('映像機能: 地デジTVチューナー');
        } else {
            checkList.push('テレビ対応PCで良いものがない場合は<a href="https://amzn.to/3Uu1mVF" target="_blank">nasne</a>が鉄板である。<a href="https://www.sony.jp/software/store/products/pctv-plus/?srsltid=AfmBOooCQRPbgAi2HrSevRc4OP5duFQdI4r8ubOghURc3QLxwDnFMbh3" target="_blank">PC TV Plus</a>というアプリは別途必要となる');
        }
    }

    // ワイヤレスマウス
    if (isDeskTop && params.wire >= 2) {
        specList.push('マウス/キーボード:　ワイヤレスマウス/ワイヤレスキーボード');
        checkList.push('配線がすっきりするためワイヤレスマウスとワイヤレスキーボードはおすすめ');
    }
    if (!isDeskTop) {
        checkList.push('操作が快適なためワイヤレスマウスを同時に購入しても良いだろう');
    }

    // 結果から有用な値を抽出
    const hasGPU = specList.some(str => str.includes('グラフィックボード:'));


    makerList = MakerArray.filter(el => {
        if (isDeskTop) {
            if (!el.hasDesktop) {
                return false;
            }
            if (hasGPU) {
                return el.hasGaming;
            }
            if (allinone) {
                return el.hasAllInOne;
            }
            if (tower) {
                return el.hasTower;
            }
            return true;
        } else {
            if (!el.hasNote) {
                return false;
            }
            if (mobile) {
                return el.hasMobile;
            }
            if (gamingnote) {
                return el.hasGamingNote;
            }
            if (standard) {
                return el.hasStandard !== false;
            }
        }
        return false;
    });
    const min = Math.min(params.cospa, params.support, params.design);
    const n_costa = params.cospa - min + 1,
        n_support = params.support - min + 1,
        n_design = params.design - min + 1;
    const weightRatio = (n_costa + n_support + n_design)/3;
    makerList.sort((makerA, makerB) => {
        let score = n_costa * (makerB.cospa - makerA.cospa) +
                    n_support * (makerB.support - makerA.support) +
                    n_design * (makerB.design - makerA.design);
        
        let subscore = 0;
        if (device !== "notebook" && params.customize >= 4) {
            subscore += makerB.towerBonus - makerA.towerBonus;
        }
        if (device === "notebook") {
            if (params.battery >= 4 && mobile) {
                subscore += makerB.mobileBonus - makerA.mobileBonus;
            }
            if (params.business === 2) {
                subscore += makerB.businessBonus - makerA.businessBonus;
            }
            if (params.game === 2) {
                subscore += makerB.gamingnoteBonus - makerA.gamingnoteBonus;
            }
        }
        return score + 2 * weightRatio * subscore;
    });

    return {
        params,
        hasGPU,
        isDeskTop,
        makerList: makerList,
        specList: specList,
        checkList: checkList
    };
}

export default analyze;